.popup {
  position: absolute;
  z-index: 100;
  right: 0;
  bottom: 0;
  padding: 8px 48px 16px 16px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.4;
  white-space: pre;
  color: white;
  background: #ff9d00;
  border-radius: 2px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  font-family: serif;
  font-size: 30px;
  line-height: 0;
  cursor: pointer;
  color: white;
}

.close::before {
  content: '×';
}

.raise_bet_link {
  text-decoration: none;
  cursor: pointer;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
