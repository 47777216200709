.button {
  white-space: nowrap;
}

.button:not(:disabled) {
  border: 1px solid #c9c9c9;
  color: #121212;
  font-weight: normal;
}

.button:not(:disabled):hover {
  border: 1px solid #7a7a7a;
  color: #121212;
  font-weight: normal;
}
