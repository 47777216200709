.container {
  position: absolute;
  z-index: 1;
  top: -12px;
  left: 0;
  display: none;
  visibility: hidden;
  min-width: 334px;
  color: #121212;
  background-color: white;
  border-radius: 2px;
  opacity: 0;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  transition:
    opacity 0.2s ease-out,
    visibility 0s 0.2s;
  transform: translateY(-100%);
}

.container::before,
.container::after {
  position: absolute;
  left: 40px;
  bottom: 0;
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  background-color: white;
  box-shadow: inherit;
  transform: translateY(50%) rotate(315deg);
}

.container--visible {
  display: block;
  visibility: visible;
  opacity: 1;
  transition:
    opacity 0.2s ease-in,
    visibility 0s;
}

.container::before {
  z-index: 2;
  left: 35px;
  width: 25px;
  height: 9px;
  box-shadow: none;
  transform: none;
}

.header {
  position: relative;
  margin-bottom: 8px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  background-color: rgba(46, 158, 0, 0.1);
}

.check {
  position: relative;
  top: 2px;
  margin-right: 8px;
  display: inline-block;
}

.link {
  text-decoration: none;
  color: #2b87db;
  transition: color 0.15s;

  &:hover {
    color: #256ba5;
  }
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #7a7a7a;
  background: none;
  border: none;
  outline: none;
  transition: color 0.15s;

  &:hover {
    color: #121212;
  }
}

.body {
  padding: 0 20px 12px 16px;
  font-size: 14px;
  line-height: 18px;

  p {
    margin: 0 0 8px;
  }
}
