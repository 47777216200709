.vas_label {
  margin-top: 4px;
  text-align: right;
}

.right-panel {
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;

  .label,
  .time {
    margin-top: auto;
    margin-bottom: auto;
    display: inline-block;
  }

  .time {
    position: relative;
    top: 2px;
    cursor: default;
  }

  .label {
    margin-right: 4px;
  }

  .label:last-child {
    margin-right: 0;
  }
}

.auction_link {
  cursor: pointer;
  display: block;
  line-height: 16px;
  font-size: 12px;
  color: #2b87db;
  transition: color 200ms linear;

  &:hover {
    color: #1a62a6;
  }
}
