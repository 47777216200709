.text {
  padding-top: 4px;
  padding-left: 16px;
  font-size: 16px;

  &.simplified-text {
    padding: 5px 0 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    text-align: left;
    letter-spacing: normal;
    color: rgba(18, 18, 18, 0.92);
  }

  &.multiple-phones {
    line-height: 20px;
  }

  &.multiple-phones.simplified-text {
    line-height: 20px;
  }
}

.preloader {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 16px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
}
