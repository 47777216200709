.container {
  margin-bottom: 15px;
  background-color: white;
}

.wrapper {
  margin: 0 auto;
  margin-bottom: 0;
  padding: 25px 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.breadcrumbs {
  margin-bottom: 22px;

  &-link {
    line-height: 20px;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    color: #666;
    border: none;
    transition: color 0.15s;

    &:hover {
      color: black;
    }
  }

  &-delimiter {
    position: relative;
    margin: 0 10px;
    display: inline-block;
    width: 5px;
    height: 8px;
    background-image: url('../icon_arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.content {
  display: flex;
  justify-content: space-between;

  &-info {
    flex-shrink: 0;
  }

  &-title {
    margin: 0;
    margin-bottom: 7px;
    padding: 0;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0;
    color: #121212;

    a {
      text-decoration: none;
      color: #2b87db;
    }
  }

  &-address {
    position: relative;
    margin-bottom: 24px;
    padding-left: 17px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 11px;
      height: 16px;
      content: '';
      background-image: url('./icon_address.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &-desc {
    display: flex;
  }

  &-text {
    margin: 0;
    max-width: 560px;
    font-size: 14px;
    line-height: 1.43;

    a {
      text-decoration: none;
      color: #2b87db;
    }
  }

  &-offers {
    margin: 0;
    margin-left: 24px;
    padding: 0;
    padding-left: 25px;
    list-style: none;
    border-left: 1px solid #d8d8d8;
    align-self: flex-start;

    li {
      &:not(:first-child) {
        margin-top: 8px;
      }

      a {
        text-decoration: none;
        color: #2b87db;
      }
    }
  }

  &-image {
    flex-shrink: 0;

    img {
      width: 228px;
      height: 150px;
      object-fit: cover;
    }
  }
}
