.link {
  align-items: center;
  color: var(--primary_100);
  display: inline-flex;
  text-decoration: none;
}

.content {
  margin: 16px 0;
}
