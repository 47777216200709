.counter {
  position: absolute;
  right: 24px;
  bottom: 24px;
  padding: 3px 4px;
  visibility: visible;
  font-size: 12px;
  line-height: 18px;
  color: var(--white_100);
  background: rgba(21, 34, 66, 0.6);
  border-radius: 4px;
}
