.item {
  font-size: 13px;
  line-height: 18px;
  color: #121212;
  text-decoration: none;
  transition: color 0.15s;
}

.item:hover {
  color: #e84260;
}
