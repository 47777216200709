.container {
  position: relative;
  margin-right: 12px;
  display: inline-block;
}

.wrapper {
  width: 100%;
  position: absolute;
  height: 100%;
  left: 0;
  padding-top: 24px;
}

.button {
  display: inline-flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
}

.green {
  fill: #2e9e00;
  color: #2e9e00;
}

.green-hover {
  fill: #257f00;
  color: #257f00;
}

.popup {
  padding: 25px 30px 20px;
  min-width: 322px;
  text-align: center;
}

.icon {
  margin-bottom: 16px;
}

.icon-small {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-left: 8px;
}

.icon-small svg {
  width: 100%;
  height: 100%;
}

.title {
  display: block;
  font-size: 16px;
  font-weight: bold;
}

.description {
  margin: 5px 0 0;
  font-size: 13px;
}

.link {
  margin-top: 20px;
}
