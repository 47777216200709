.container {
  border-bottom: 1px solid var(--gray10_100);
}

.container.closed .content {
  display: none;
}

.container.closed svg {
  transform: scaleY(-1);
}

.title-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.title {
  padding: 12px 0;
}

.title > * {
  margin: 0;
  padding: 0;
}

.title,
.title > * {
  font-size: var(--fontSize_16px);
  font-weight: 700;
  line-height: var(--lineHeight_24px);
  color: var(--black_100);
}

.arrow-wrapper {
  margin-left: 12px;
}

.content {
  padding: 12px 0;
}

.content,
.content li,
.content td {
  font-size: var(--fontSize_14px);
  font-weight: 400;
  line-height: var(--lineHeight_20px);
  color: var(--superblack_100);
}

.content table,
.content p,
.content ul,
.content ol {
  margin: 0;
  padding: 0;
}

.content p {
  margin-bottom: 12px;
}

.content ul,
.content ol {
  list-style: none;
}
