.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image.image--layout {
  object-fit: contain;
  background-color: white;
}
