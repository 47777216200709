.amenities-list {
  margin-top: 8px;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  height: 48px;

  @media (max-width: 1120px) {
    height: 84px;
  }

  .amenity-item {
    margin-right: 20px;
    min-width: 162px;
    height: 20px;

    &:nth-child(odd) {
      margin-bottom: 8px;
    }

    @media (max-width: 1120px) {
      margin-bottom: 8px;
    }
  }
}
