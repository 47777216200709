.root {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.item {
  flex: 1;
  background-color: var(--white_100);
  border: 1px solid var(--gray6_100);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;

  .label {
    margin-bottom: 16px;
  }

  & + & {
    margin-left: 16px;
  }

  .active {
    background: var(--gray6_100);
    border-color: var(--black_100);
  }
}
