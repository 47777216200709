.notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 7;
  height: 40px;
  background: rgba(18, 18, 18, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #fff;
}

.notification-error {
  background: rgba(255, 80, 80, 0.9);
}

.close {
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 18px;
  line-height: 0;
  font-family: serif;
}

.close::before {
  content: '×';
}

.link {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}
