.root {
  width: 800px;
  height: 650px;
}

.left-column {
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  height: 100%;
  background-image: url('./Group.png');
  background-color: var(--superblack_100);
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.right-column {
  width: auto;
  height: 100%;
  margin-left: 320px;
}

.title-block {
  padding: 48px 28px 0 40px;
}

.main-title {
  margin-bottom: 16px;
}
