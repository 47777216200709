.wrapper {
  display: inline-block;
}

.link {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  text-transform: lowercase;
  cursor: pointer;
  color: #2b87db;
  border-bottom: 1px dashed #2b87db;
}

.link:hover {
  color: #256ba5;
}

.link:visited {
  color: #909;
}

.dropdown-container {
  margin: -12px;
  padding: 0;
}

.item {
  cursor: pointer;
  padding: 8px 12px;
  list-style: none;

  &:hover {
    background: var(--gray6_100);
  }
}

.item-content {
  grid-column-gap: 8px;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 16px;
  grid-auto-flow: column;
}

.item-selected-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
