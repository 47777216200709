@import '@cian/ui-kit/colors/variables.css';
@import '@cian/ui-kit/typography/variables.module.css';

.price {
  margin-bottom: 12px;
}

.value {
  color: var(--black_100);
  font-weight: bold;
  font-size: var(--fontSize_22px);
  line-height: var(--lineHeight_28px);
}

.currency {
  color: var(--gray60_100);
  font-weight: bold;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_20px);
}
