.container {
  position: relative;
  text-overflow: ellipsis;
}

.user_info {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
}

.popup {
  width: 240px;
}

.links {
  margin: 0;
  padding: 11px 0 0 8px;
  list-style: none;
}

.links-link {
  font-size: 13px;
  font-weight: bold;

  svg {
    margin-right: 7px;
    vertical-align: top;
  }

  a {
    padding: 8px 0;
    display: block;
    line-height: 1.2;
    text-decoration: none;
    color: #2b87db;

    &:hover {
      color: #e84260;
    }
  }
}

.delimiter {
  margin: 0;
  height: 1px;
  background-color: #e4e4e4;
  border: none;
  width: 266px;
  margin-left: -13px;
}

.all_offers {
  padding: 0 0 10px;

  a {
    margin: 0 10px;
    padding: 7px 0 8px;
    display: block;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    color: #2b87db;

    &:hover {
      color: #e84260;
    }
  }
}

.container-reverse {
  .all_offers {
    padding: 20px;
    line-height: 16px;
    text-align: left;
  }

  .offer-link,
  .link-item {
    margin-right: 0;
    margin-left: 0;
    padding: 0;
  }

  .offer-link + .offer-link,
  .links-link + .links-link {
    margin-top: 16px;
  }

  .links {
    padding: 20px;
  }

  .links-link svg {
    margin-right: 8px;
  }
}
