.label {
  font-size: 14px;
  line-height: 20px;
  color: #2e9e00;
}

.container {
  position: relative;
}

.tooltip-container {
  width: 276px;
}
