.container {
  display: flex;
  align-items: center;
}

.title {
  display: flex;
}

.tooltip-container {
  position: relative;
  top: auto;
  margin: 0;
  padding: 0;
  width: auto;

  & > div {
    position: absolute;
    left: 50%;
    bottom: 16px;
    margin-bottom: 8px;
    padding: 6px 12px;
    width: 198px;
    background-color: var(--black_control_80);
    border: none;
    border-radius: 4px;
    transform: translateX(-50%);
  }
}

.info-icon {
  display: flex;
  cursor: pointer;

  & svg {
    margin-left: 4px;

    & circle {
      fill: var(--primary_100);
    }

    & path {
      color: var(--primary_100);
    }
  }
}
