.link {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 0 0 auto;
  height: 200px;
  text-decoration: none;
  cursor: pointer;
  background-color: #e8e9ec;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition:
    transform 200ms,
    box-shadow 200ms;
}

.link:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
}

.title {
  position: absolute;
  right: 16px;
  left: 16px;
  top: 16px;
  overflow: hidden;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: #152242;
  white-space: pre-wrap;
}

.image {
  max-width: 200px;
}

.label {
  padding: 4px 12px;
  display: inline-block;
  position: absolute;
  left: 16px;
  bottom: 16px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: var(--white_100);
  font-weight: 700;
}
