/* stylelint-disable selector-class-pattern */

.container {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;

  &-container {
    margin-bottom: 22px;
    display: flex;
    flex-direction: row;
    min-height: 114px;
  }
}

.media {
  margin-right: 27px;

  &-multi-ads {
    margin-right: 24px;
  }
}

.author_info {
  padding: 8px 12px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;

  &--platinum {
    background-color: rgba(18, 18, 18, 0.05);
  }
}

.phone {
  align-self: flex-end;
}

.chats_block {
  align-self: flex-end;
}

.chats_block .phone {
  margin-bottom: 10px;
}

.user_info {
  margin-right: -6px;
  margin-left: 0;
  padding-right: 0;
  width: 160px;
}

.header {
  margin-bottom: 12px;
  &-title {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
  }

  &-classType {
    margin-left: 6px;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #7a7a7a;
  }

  &-subTerm {
    margin-top: 4px;

    & div {
      display: inline-block;
    }

    &-list {
      margin: 0;
      padding: 0;
    }
  }

  &-title {
    display: flex;
  }

  &-subtitle {
    font-size: 13px;
    opacity: 0.9;
  }

  &-subtitle-coworking {
    margin-top: 4px;
  }

  &-subtitle-count > div {
    margin-top: 4px;
  }
}

.address {
  padding-left: 0;
}

.description,
.description-top {
  margin: 16px 0 4px;
  padding-right: 10px;
  width: 100%;
  height: auto;
}

.offer_menu {
  margin-left: 0;
}

.footer {
  margin-top: 20px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1150px) {
  .footer-top {
    flex-direction: column;
  }
}

.footer-menu {
  margin-top: 0;
}

.recidivist {
  height: calc(3 * 18px + 13px);
}

.commercialMortgageBanner {
  display: block;
}

.commercialMortgageBanner div {
  display: block;
}

@media (max-width: 1115px) {
  .author_info-top {
    display: none;
  }
}

@media (max-width: 1240px) {
  .author_info-multi-ads {
    display: none;
  }
}

@media (max-width: 1284px) {
  .hideable {
    display: none;
  }
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.info {
  display: flex;
  width: 80%;

  .info-section + .info-section {
    margin-top: 12px;
  }
}

@media (min-width: 1240px) {
  .info {
    flex-flow: column nowrap;
    flex: 1 1 auto;
  }
}

@media (max-width: 1240px) {
  .info {
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 1240px) {
  .agent_brand_wrapper {
    display: none;
  }
}

.commonInfo {
  width: 100%;
}

.label {
  margin-top: 12px;
  margin-bottom: -4px;
}

.row-area-parts {
  display: flex;
  flex-direction: row;
}

.area-parts {
  padding-left: 0;
  display: inline-block;
  list-style: none;
  padding-inline-start: 0;
}

.area-parts-item {
  margin-top: 0;
  margin-right: 8px;
  display: inline-block;
  width: 140px;
}

.bottom-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.coworking-amenities {
  margin-top: 12px;
}

.shield-wrapper {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
  width: calc(100% - 16px * 2);

  &:empty {
    display: none;
  }
}

.shields-in-content-wrapper {
  margin-bottom: 16px;

  &:empty {
    display: none;
  }
}

.tooltip {
  font-weight: normal;
  line-height: 22px;
  color: rgb(18, 18, 18);
}

.offer-title-content-container {
  display: flex;
  flex-direction: column;
}

.message-button {
  font-weight: 700;
}
