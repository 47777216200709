.directions {
  padding: 90px 16px 60px;
  display: flex;
  min-height: 200px;
  flex-wrap: wrap;
}

.directions-column {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.preloader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}

.footer {
  display: flex;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid #d8d8d8;
  align-items: center;
  justify-content: center;
}
