.banner-text-bold ~ p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.banner-text-bold {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  margin: 4px 0 8px;
}
