.wrapper {
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;

  .container {
    padding: 30px 35px;
    background-color: white;
    border: 1px solid #e4e4e4;

    &-shifted {
      margin-top: -16px;
    }
  }

  .header {
    margin: 0 0 4px;
    padding: 0;
    display: block;
    font-weight: 700;
    color: #121212;
  }

  .link {
    margin-right: 15px;
    display: inline-block;
    line-height: 25px;
    text-decoration: none;
    color: #2b87db;
    transition: color 0.15s;
    cursor: pointer;

    &:hover {
      color: #256ba5;
    }
  }
}
