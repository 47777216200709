.relative-container {
  position: relative;
}

.overflow-container {
  overflow: hidden;
}

.groups-container {
  display: flex;
  transition: transform 1s ease;
}

.group {
  display: flex;
  width: 100%;
  flex-shrink: 0;
}

.button {
  position: absolute;
  top: calc(50% - 16px);
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  color: #999;
  background-color: white;
  border: solid 1px #f4f4f4;
  outline: none;

  &:hover:not([disabled]) {
    color: #2b87db;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  }

  &.prev {
    left: -13px;
  }

  &.next {
    right: -13px;
  }
}
