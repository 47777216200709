.photos_container {
  width: 450px;
  height: 361px;
}

.photos_container--big {
  width: 553px;
}

.media {
  position: relative;
  margin-right: 1px;
  min-width: 450px;
  height: 361px;
  flex-basis: 262px;
}

.media--big {
  min-width: 553px;
}

.preview_photos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 361px;
}

.preview_photo {
  position: relative;
  width: 103px;
  height: 89px;
}

.main {
  margin-left: 24px;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: space-between;
}

.preview_placeholder {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 103px;
  height: 89px;
  background-color: #f4f4f4;
  opacity: 0.9;
  justify-content: center;
  align-items: center;
}

.other_photos {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 103px;
  height: 89px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: rgba(71, 82, 88, 0.6);
  justify-content: center;
  align-items: center;
}

.price_flex_container {
  margin: 24px 0;
  display: flex;
}

.address_info {
  margin-top: 0;
}

@media (max-width: 1240px) {
  .agent_brand_wrapper {
    display: none;
  }
}

.horizontal_agent_brand {
  display: none;
}

@media (max-width: 1240px) {
  .horizontal_agent_brand {
    display: block;
  }
}

.media_wrapper {
  display: flex;
}

.media_brand_wrapper {
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  width: 80%;

  .info-section + .info-section {
    margin-top: 12px;
  }
}

@media (min-width: 1240px) {
  .info {
    flex-flow: column nowrap;
    flex: 1 1 auto;
  }
}

@media (max-width: 1240px) {
  .info {
    flex-direction: column;
    width: 100%;
  }
}

.panels_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1150px) {
  .panels_container {
    flex-flow: row;
  }
}

@media (max-width: 1150px) {
  .panels_container {
    flex-direction: column;
  }
}

.label {
  margin-bottom: 8px;
}
