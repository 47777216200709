.container {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  align-items: center;

  &:empty {
    display: none;
  }
}

.item {
  display: flex;

  &:empty {
    display: none;
  }
}
