/* stylelint-disable selector-class-pattern */

:root {
  --bg-padding: 10px;
  --avatar-padding: 5px;
}

.container {
  margin-top: 6px;
  margin-left: calc(16px - var(--bg-padding));
  padding-right: 26px;
}

.containerHorizontal {
  margin-bottom: 6px;
  display: inline-block;
}

.userAvatar {
  margin-top: 4px;
  margin-left: calc(var(--bg-padding) - var(--avatar-padding));
  padding: var(--avatar-padding);
  display: inline-block;
  line-height: 0;
  background-color: white;
  border: none;

  img {
    width: 50px;
    height: 50px;
  }
}

.userInfoContainer {
  display: flex;
  flex-direction: column;
}

.userInfoHorizontalContainer {
  display: block;
}

.userInfo {
  &--horizontal {
    margin-left: -6px;
    padding: 3px 6px 8px;
    display: inline-block;
  }

  &-name {
    width: 152px;
    font-family: Lato, sans-serif;
    font-size: 13px;
    line-height: 1.23;
    text-align: left;
    color: #121212;

    &--horizontal {
      display: inline-block;
      width: auto;
    }
  }

  &-company {
    margin-top: 4px;
    width: 152px;
    font-family: Lato, sans-serif;
    font-size: 13px;
    line-height: 1.23;
    text-align: left;
    color: #7a7a7a;

    &--horizontal {
      display: inline-block;
      width: auto;
    }
  }

  &-badges {
    margin-top: 4px;

    &--horizontal {
      margin-top: 0;
      display: inline-block;
    }
  }

  &-badge {
    margin-top: 4px;
    display: inline-flex;
    vertical-align: top;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &--horizontal {
      margin-right: 8px;
      vertical-align: middle;
    }

    .honest-work {
      cursor: default;
    }
  }

  &-textBadge {
    padding: 0 6px;
    font-size: 10px;
    font-weight: bold;
    line-height: 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #585858;
    background-color: rgba(18, 18, 18, 0.1);
    border-radius: 3px;

    &--green {
      margin-top: 6px;
      margin-right: auto;
      color: #2da000;
      background-color: rgba(45, 160, 0, 0.1);
    }

    &.greenLight {
      margin-right: auto;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 1px;
      color: #2da000;
      background-color: rgba(46, 158, 0, 0.07);
    }
  }
}

.popup {
  right: 0;
  margin-top: 12px;
  width: 240px;
}

.links {
  margin: 0;
  padding: 11px 10px 12px 20px;
  list-style: none;

  &-link {
    font-size: 13px;
    font-weight: bold;

    svg {
      margin-right: 7px;
      vertical-align: top;
    }

    a {
      padding: 8px 0;
      display: block;
      line-height: 1.2;
      text-decoration: none;
      color: #2b87db;

      &:hover {
        color: #e84260;
      }
    }
  }
}

.delimiter {
  margin: 0;
  height: 1px;
  background-color: #e4e4e4;
  border: none;
}

.allOffers {
  padding: 7px 0 10px;
  text-align: center;

  a {
    margin: 0 10px;
    padding: 7px 0 8px;
    display: block;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    color: #2b87db;

    &:hover {
      color: #e84260;
    }
  }
}

.rosreestr-verified-wrapper {
  margin-top: 8px;
}
