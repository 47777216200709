.container {
  position: fixed;
  z-index: 21;
  right: 40px;
  bottom: 0;
  overflow: hidden;
  width: 320px;
  height: 480px;
  background: white;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.close {
  position: absolute;
  top: 10px;
  right: 14px;
}
