/* stylelint-disable selector-class-pattern */

.container {
  padding-left: 24px;
  white-space: normal;

  &--submitted-small {
    width: 270px;
  }
}

.preloader {
  margin: 0 auto;
  display: block;
}

.form {
  position: relative;
  white-space: normal;
}

.links {
  margin: 0;
  padding: 0;
  list-style: none;

  & > :last-child {
    margin-bottom: 0;
  }

  &-link {
    position: relative;
    font-size: 13px;
    font-weight: bold;

    &--active {
      margin-bottom: 11px;
    }
  }

  &-linkIcon {
    position: absolute;
    top: 10px;
    left: -22px;
  }

  &-linkAnchor {
    padding: 8px 0;
    display: block;
    line-height: 1.2;
    text-decoration: none;
    color: #2b87db;

    &:hover {
      color: #e84260;
    }

    &--active {
      cursor: default;
      color: #0f0f0f;

      &:hover {
        color: #0f0f0f;
      }
    }
  }
}

.header {
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #121212;
}

.textarea {
  margin-top: 2px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  min-height: 56px;
  resize: none;

  &--error {
    border-color: red;
  }
}

.button_back {
  position: absolute;
  top: -4px;
  left: -34px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #7a7a7a;
  background: none;
  border: none;
  outline: none;

  &:hover {
    color: #121212;
  }
}

.feedback {
  position: relative;
}

.feedback-close {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 0;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #999;
  border: none;
  outline: none;

  &:hover {
    color: #121212;
  }
}

.feedback-title {
  position: relative;
  margin-bottom: 8px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #121212;

  svg {
    position: absolute;
    top: 4px;
    left: -23px;
  }
}

.feedback-body {
  margin-bottom: 12px;
  font-family: Lato;
  font-size: 14px;
  line-height: 1.43;
  color: #121212;
}

.feedback-form {
  div:first-child {
    margin-bottom: 12px;
    width: 260px;
  }
}

.feedback-preloader-body {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
}

.feedback-preloader-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
