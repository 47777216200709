.building-link {
  font-size: 14px;
  line-height: 1.43;
  text-decoration: none;
  color: #2b87db;

  &:hover {
    color: #256ba5;
  }

  &:visited {
    color: #909;
  }
}
