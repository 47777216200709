.container {
  position: relative;
  height: 100%;
}

.promo-container {
  max-width: calc(100% - 30px);
}

.promo-container-isnew {
  top: 40px;
}

.photos {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: none;
}

.badges-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  width: auto;
  height: 28px;
  justify-content: flex-end;
  align-items: flex-end;
}

.badges-container > * {
  margin-left: 5px;
}
