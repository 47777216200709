.container {
  text-align: left;
}

.header {
  margin-bottom: 8px;
  text-align: left;
}

.vas {
  display: flex;
}

.description {
  margin-bottom: 20px;
  text-align: left;
}

.link {
  text-decoration: none;
}
