.phone {
  font-size: 16px;
  font-weight: normal;
}

/* stylelint-disable-next-line selector-class-pattern */
.hoverText {
  font-size: 14px;
}

.simplified-phone {
  font-size: 14px;
  font-weight: bold;
}
