.offer-container {
  box-sizing: border-box;
  max-height: 1000vh;
  font-size: 14px;
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 1px;
  opacity: 1;
  transition:
    opacity 0.3s ease,
    background-color 0.3s ease;

  &:hover {
    background-color: #eef9ff;
  }

  & + & {
    border-top: none;
  }

  &.offer-hidden {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition:
      opacity 0.3s ease,
      max-height 0s 0.3s;
  }

  &.hide-offer-animation {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.7s;
  }
}

.offer-container.top3 {
  background-color: white;
  border-color: #e4e4e4;

  &:hover {
    background-color: #eef9ff;
  }
}

.offer-container.colorized {
  background-color: #fff5d4;

  &:hover {
    background-color: #ffecbd;
  }
}

.container {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  color: #121212;
  flex: 0 0 auto;

  &.offer-unclickable {
    cursor: default;
  }

  &.offer-similar {
    padding-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover .dynamic-button,
  &.touch-device .dynamic-button {
    opacity: 1;
  }

  .header {
    text-decoration: none;
    color: #121212;
    transition: color 0.3s ease;
  }

  &:not(.offer-unclickable):hover .header,
  .header:hover {
    color: #e84260;
  }
}

.main-container {
  display: flex;
}

.main {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: space-between;
}

.media {
  position: relative;
  margin-right: 16px;
  min-width: 262px;
  height: 226px;
  flex-basis: 262px;
}

.panels-container {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.left-panel {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;

  .button {
    margin-top: auto;
    height: 28px;
  }

  .button:not(:first-child) {
    margin-left: 8px;
  }

  .dynamic-button {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.info-container {
  display: flex;
  flex-flow: row nowrap;
}

.info {
  display: flex;
  width: 80%;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  .info-section + .info-section {
    margin-top: 12px;
  }
}

.sub-block {
  margin-top: 16px;
}

.main-info {
  padding-top: 4px;
  display: flex;

  & > div:first-child {
    margin-right: 16px;
    min-width: 282px;
  }
}

.similar {
  padding-left: 278px;
  display: flex;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 45px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  border-top: 1px solid #e4e4e4;
  transition: color 0.3s ease;

  &:hover {
    color: #256ba5;
  }
}

.map-container {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease;
}

.map {
  height: 248px;
  transition: transform 0.3s ease;
  transform: translateY(-100%);
}

.map-active {
  height: 248px;

  .map {
    transform: translateY(0);
  }
}

.base-label {
  margin-top: 4px;
}

.media-top3 {
  height: 345px;
}

.additional-photos {
  margin-top: 6px;
  display: flex;
  flex-direction: row;
}

.left-photo {
  margin-right: 6px;
  width: 128px;
  height: 113px;
}

.right-photo {
  width: 128px;
  height: 113px;
}

.photos-container {
  height: 226px;
}

.top3-description {
  max-height: 140px;
}

.titled-description {
  max-height: 60px;
}

.send-message-button-wrapper {
  position: relative;
  margin-bottom: 6px;
  margin-left: -15px;
}

.price-flex-container {
  display: flex;
}

.card_photo_container {
  margin-right: 16px;
  display: flex;
}

.light {
  border: none;
}

@media (max-width: 1240px) {
  .price-flex-container {
    flex-direction: column;
  }
}

.label {
  margin-top: 12px;
  margin-bottom: 8px;
}
