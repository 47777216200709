.address {
  padding-left: 20px;
}

.header-link {
  padding-bottom: 0;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  color: black;

  &:hover {
    color: #e84260;
  }
}

.building {
  font-size: 13px;
  line-height: 1.38;
  text-decoration: none;

  &-link {
    text-decoration: none;
    color: #2b87db;

    &:hover {
      color: #256ba5;
    }

    &:visited {
      color: #909;
    }
  }
}

.reliable-wrapper {
  position: absolute;
  margin-left: -19px;
  cursor: pointer;

  &:hover .reliable-icon path {
    color: #257f00;
    fill: #257f00;
  }

  & .reliable-icon {
    width: 12px;
    height: 12px;
  }
}

.tooltip-container {
  width: 324px;
  text-align: center;
  margin: 12px 0 28px;

  .icon {
    margin: 0 0 5px;
    width: 48px;
    height: 48px;
  }

  .title {
    margin: 0 0 4px;
    display: block;
    font-size: 16px;
  }

  p {
    margin: 0 0 20px;
    font-size: 13px;
    line-height: 20px;
  }

  .link {
    display: inline-block;
    width: 143px;
    height: 28px;
    font-size: 13px;
    font-weight: bold;
    line-height: 25px;
    text-decoration: none;
    color: rgb(43, 135, 219);
    border: solid 1px rgba(43, 135, 219, 0.5);
    border-radius: 42px;
  }
}

.region-metro {
  margin-top: 6px;
  font-size: 13px;
  line-height: 19px;
  color: #333;
}

.address-path {
  width: 100%;
}
