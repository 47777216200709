.container {
  position: relative;
  display: inline-block;
  font-weight: normal;
  line-height: 1;
}

.entering {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

.enter {
  z-index: 1000;
  visibility: visible;
  pointer-events: initial;
  opacity: 1;
  transition:
    opacity 0.3s ease,
    visibility 0s;
}

.enter + .label {
  z-index: 1;
}

.leave {
  z-index: -1;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    visibility 0s 0.3s;
}

.label {
  position: relative;
  cursor: pointer;

  &.label--disabled,
  &.label--popup-disabled {
    cursor: default;
  }

  &.label--disabled {
    color: #7a7a7a;
  }
}

.popup-container {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  padding-bottom: 30px;
  width: 350px;
  text-align: center;

  &.popup-container--left {
    margin-left: 100%;
    transform: translateX(-100%);
  }

  &.popup-container--right {
    margin-right: 100%;
    transform: translateX(100%);
  }

  &.popup-container--center {
    right: 50%;
    transform: translateX(50%);
  }

  &.popup-container--left .popup::after {
    right: 0;
    transform: translateX(-15px) rotate(45deg);
  }

  &.popup-container--right .popup::after {
    left: 0;
    transform: translateX(15px) rotate(45deg);
  }

  &.popup-container--center .popup::after {
    transform: translateX(-50%) rotate(45deg);
  }
}

.popup {
  position: relative;
  z-index: 1;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);

  &::after {
    position: absolute;
    bottom: -5px;
    width: 12px;
    height: 12px;
    content: '';
    background: white;
    border-radius: 2px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    transform: rotate(45deg);
  }
}

.popup-content {
  position: relative;
  z-index: 1;
  padding: 16px;
  background-color: white;
  background-origin: border-box;
  border-radius: 2px;
}

.link {
  padding: 5px 16px 7px;
  display: inline-block;
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  border: solid 1px color(#2b87db a(50%));
  border-radius: 42px;
  outline: none;
  transition: color 0.3s ease;

  &:hover {
    color: #1a62a6;
    border: solid 1px color(#1a62a6 a(50%));
  }
}
