/* stylelint-disable-next-line */
:global(#legacy-commercial-serp-frontend) {
  display: flex;
  flex: 1 1 auto;
}

.wrapper {
  margin: 0 auto 15px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.delimiter {
  margin: 0 auto 20px;
  padding: 0 20px;
  width: 100%;
  max-width: 1376px;
}

.delimiter hr {
  margin: 0;
  height: 1px;
  border: 0;
  background: #e4e4e4;
}

.serp {
  flex: 1;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-width: 1135px;
  min-height: 160px;
  background-color: #f3f4f6;

  & > :last-child {
    margin-bottom: 0;
  }
}

.serp--light {
  background-color: white;
}

.preloader {
  position: fixed;
  display: flex;
  inset: 0;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.preloadOverlay {
  position: absolute;
  z-index: 10;
  inset: 0;
  top: 1px;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 1;
  transition: opacity 0.3s linear;
  will-change: opacity;
}

.seoText {
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
  font-size: 14px;
  line-height: 18px;
  color: #7a7a7a;
  order: 1;

  a {
    padding-bottom: 1px;
    text-decoration: none;
    color: #2b87db;
    transition: color 150ms;

    &:hover {
      color: #256ba5;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0;

    li {
      display: inline;
      font-size: 14px;
    }

    li::after {
      margin: 0 5px;
      content: '∙';
    }

    li:last-child::after {
      margin: 0;
      content: '';
    }
  }
}

.coworking-profit-banner-container {
  margin: 0 20px;
}

.coworking-profit-banner {
  margin: 16px auto 0;
  max-width: 1336px;
}

.coworking-ad-modal-mini {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
}

.coworking-popup {
  position: fixed;
  left: 16px;
  z-index: 10;
}

@media print {
  .serp {
    /* Fix for firefox */
    display: block;
  }

  .seoText {
    display: none;
  }
}

.exclude-doubles-banner-container,
.best-place-banner-container,
.multi-ads-banner-container {
  margin: 16px auto 0;
  padding: 0 20px;
  max-width: 1376px;
  width: 100%;
}
