.report-button {
  width: auto;

  @media (max-width: 1320px) {
    padding: 0;
    gap: 0;

    .report-button-text {
      display: none;
    }
  }
}

.button-active {
  background-color: rgba(43, 135, 219, 0.15);
}
