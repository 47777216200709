.container {
  display: flex;
}

.name {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: #ff8400;
}

.name--hovered {
  color: #cc6e09;
}
