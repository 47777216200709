.main-info {
  display: flex;
}

.agent {
  position: relative;
  margin-top: 16px;
  padding: 12px 12px 20px;
  width: 554px;
  background-color: rgba(18, 18, 18, 0.05);
  border-radius: 2px;
}

.agent-section {
  margin-top: 8px;

  &:empty,
  &:first-child {
    margin: 0;
  }
}

.avatar {
  display: block;
  width: 70px;
  height: 70px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.name {
  margin-bottom: 4px;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  color: #121212;
}

.name_wrapper {
  margin-right: 6px;
}

.company-name {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #121212;
}

.name + .company-name {
  margin-top: 0;
  margin-bottom: 4px;
  color: #7a7a7a;
}

.text-badge {
  padding: 1px 6px;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #585858;
  background-color: rgba(18, 18, 18, 0.1);
  border-radius: 2px;

  &.green {
    color: #2e9e00;
    background-color: rgba(46, 158, 0, 0.07);
  }
}

.badge {
  margin-right: 6px;
  display: inline-block;
}

.badge-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-info {
  position: relative;
  z-index: 3;
  padding-top: 2px;
  padding-left: 2px;
  display: inline-block;
}

.user-info-action {
  background-color: rgba(43, 135, 219, 0.1);
}

.experience {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.43;
  color: #7a7a7a;
}

.phone_button {
  margin-top: 24px;
}

.main_info-data {
  margin-left: 16px;
}

.newbuilding-label {
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 12px;
  display: block;
}

.honest-realtor-new-commercial-wrapper {
  display: flex;
  width: fit-content;
}
