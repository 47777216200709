.container {
  padding: var(--unit_5, 20px);
  display: grid;
  text-decoration: none;
  background-color: white;
  grid-template-columns: 375px 1fr 232px 48px;
  grid-column-gap: var(--unit_2, 8px);
  position: relative;
  border: 1px solid #e8e8e8;

  &:hover {
    border-color: #deeaf1;
    background-color: #eef9ff;
  }
}

.photo-block {
  position: relative;
  height: 295px;
}

.main-content-block {
  padding: 0 var(--unit_6, 24px);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.publisher-block {
  padding: 0 var(--unit_4, 16px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-content {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_1, 4px);
}

.content-container {
  display: grid;
  grid-row-gap: var(--unit_4, 16px);
}

.footer-container {
  margin-top: var(--unit_4, 16px);
  display: flex;
  overflow: hidden;
  overflow-y: scroll;
  flex-direction: column;
  /* Скролл должен идти от 5 строк, то есть 5 показываются, а остальные скрываются за скролом */
  max-height: calc(var(--unit_4, 16px) * 5);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.publisher-avatar-wrapper {
  margin-bottom: var(--unit_3, 12px);
}

.more-info-button-wrapper {
  margin-top: var(--unit_5, 20px);
}

.subtitle-wrapper {
  padding-bottom: var(--unit_6, 24px);
}

.title-wrapper {
  padding-bottom: var(--unit_2, 8px);
}

.empty {
  margin-bottom: 0;
}

.close-block {
  display: flex;
  justify-content: flex-end;
}

.transports-container {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_0, 2px);

  &:empty {
    display: none;
  }
}

@media (max-width: 1279px) {
  .footer-container {
    /* Скролл должен идти от 4 строк, то есть 4 показываются, а остальные скрываются за скролом */
    max-height: calc(var(--unit_4, 16px) * 4);
  }
}
