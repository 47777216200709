.wrapper {
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.container {
  padding: 35px 30px 58px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #e4e4e4;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  &-item {
    margin-right: 25px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      margin-right: 17px;
      margin-left: -1px;
      padding: 0 7px 2px;
      font-size: 16px;
      font-weight: bold;
      color: black;
      border-bottom: 3px solid #0071db;

      &:not(:first-child) {
        margin-left: -7px;
      }
    }
  }

  &-itemLink {
    font-size: 16px;
    line-height: 1.38;
    text-decoration: none;
    color: #3f86df;
  }
}

@media print {
  .wrapper {
    display: none;
  }
}
