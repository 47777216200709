/* stylelint-disable selector-class-pattern */

.container {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 12px;
    padding-bottom: 14px;
    border-bottom: 1px solid #e4e4e4;
  }

  &--notEmpty:first-child {
    margin-top: -18px;
  }
}

.container.simplified {
  padding-right: 138px;

  &.container--notEmpty {
    margin-top: 0;
  }

  .comment .comment-label {
    display: none;
  }

  .comment .comment-textarea {
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    text-align: left;
    color: #121212;
  }

  .comment--notEmpty {
    padding-top: 0;
  }

  .comment-delete {
    top: -5px;
    right: 12px;
    transform: initial;
  }
}

.comment {
  position: relative;

  &--notEmpty {
    padding-top: 18px;
  }

  &-label {
    position: absolute;
    top: 0;
    font-size: 11px;
    font-style: italic;
    color: #7a7a7a;
  }

  &-textarea {
    padding: 0;
    width: 100%;
    max-height: 85px;
    font-family: Lato, Arial, sans-serif;
    font-size: 13px;
    font-style: italic;
    line-height: 1.38;
    resize: none;
    color: #121212;
    background: none;
    border: none;
    outline: none;
  }

  &-delete {
    position: absolute;
    top: 50%;
    right: -49px;
    transform: translateY(-50%);
  }

  &-deleteButton {
    padding: 8px;
    cursor: pointer;
    color: #c9c9c9;
    background: none;
    border: 0;
    outline: none;

    &:hover {
      color: #7a7a7a;
    }
  }
}
