.popup-triggers {
  margin-top: 18px;
  display: flex;
  justify-content: flex-start;

  > :last-child {
    margin-left: 40px;
  }
}

.popup-actions {
  margin-top: 32px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
}
