/* stylelint-disable-next-line selector-class-pattern */
.timeLabel {
  margin-left: auto;
  min-width: 90px;
  height: 16px;
  font-family: Lato;
  font-size: 12px;
  line-height: 1.33;
  text-align: right;
  color: #7a7a7a;

  /* stylelint-disable-next-line selector-class-pattern */
  &:hover {
    .relative {
      opacity: 0;
      transition: opacity 0.1s ease;
    }

    .absolute {
      opacity: 1;
      transition: opacity 0.1s ease 0.1s;
    }
  }
}

.relative,
.absolute {
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.relative {
  display: inline-block;
  opacity: 1;
  transition: opacity 0.1s ease 0.1s;
}

.absolute {
  opacity: 0;
  transition: opacity 0.1s ease;
  transform: translateY(-100%);
}
