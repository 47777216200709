.container {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: var(--unit_1, 4px);
  display: flex;
}

.remoteness {
  &::before {
    content: '・';
    color: var(--black_100);
  }
}
