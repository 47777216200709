.container {
  all: initial;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #121212;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-text-size-adjust: 100%; /* чтобы шрифт не увеличивался при повороте экрана на iOS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before,
  &::after {
    all: initial;
    box-sizing: border-box;
    font-family: Lato, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #121212;
    -webkit-text-size-adjust: 100%; /* чтобы шрифт не увеличивался при повороте экрана на iOS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 45%;
  transform: translate(-50%, -50%);
}
