.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 318px;
}

.text {
  margin: 0 0 20px;
  max-width: 80%;
  text-align: center;
}
