.container {
  margin: 16px 20px 0 0;
  width: 100%;
  max-width: 460px;

  --floor_col_width: 21%;
  --type_col_width: 29%;
  --area_col_width: 20%;
  --price_col_width: 27%;
}

.row {
  margin-bottom: 16px;
  display: flex;

  > :last-child {
    margin-right: 0;
  }
}

.floor,
.type,
.area,
.price {
  margin-right: 1%;
  flex-grow: 1;
  text-align: left;
  justify-content: flex-end;
  align-items: flex-start;
}

.floor {
  width: var(--floor_col_width);
}

.type {
  width: var(--type_col_width);
}

.area {
  width: var(--area_col_width);
}

.price {
  width: var(--price_col_width);
}

@media (max-width: 1440px) {
  .container {
    max-width: 436px;
  }
}

@media (max-width: 1320px) {
  .type {
    display: none;
  }
}
