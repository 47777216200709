:root {
  --mediaWidth: 20%;
  --minContainerHeight: 246px;
}

.container {
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;

  &:hover {
    background-color: #eef9ff;
    border-color: #deeaf1;
  }

  &--colorized {
    background-color: rgb(255, 244, 212);

    &:hover {
      background-color: rgb(255, 236, 189);
    }
  }

  &.represent {
    background-color: rgba(46, 158, 0, 0.04);

    &:hover {
      background-color: rgba(46, 158, 0, 0.1);
    }
  }
}

.content {
  box-sizing: border-box;
  width: 100%;
  min-height: var(--minContainerHeight);
}

.content-top3 {
  box-sizing: border-box;
  width: 100%;
  max-width: calc(100% - 552px);
}

.media {
  position: relative;
  margin-right: 16px;
}
