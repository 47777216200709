.container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100px;
}

.input-currency {
  margin-left: 5px;
  content: '₽';
}

.input {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  line-height: 26px;
}

.bet_increment {
  margin-right: 8px;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: #2b87db;
  background: rgba(43, 135, 219, 0.1);
  border-radius: 50%;
}
