.tooltip {
  max-width: 290px;
  font-size: 14px;
  line-height: 20px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 4px;
}

.link {
  display: block;
  text-decoration: none;
  color: var(--primary_100);
  margin-bottom: 8px;
}

.title-property-container {
  display: flex;
  align-items: center;
}

.title-property {
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  margin-left: 4px;
}

.title-icon {
  position: relative;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
}

/* stylelint-disable selector-class-pattern */
.title-icon-3 {
  background-image: url('assets/level_3_blank.svg');
}

.title-icon-4 {
  background-image: url('assets/level_4_blank.svg');
}
