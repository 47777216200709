.factoid-item {
  display: inline-block;
  font-size: 13px;
  opacity: 0.9;

  &::after {
    margin: 0 8px;
    display: inline-block;
    content: '\2219';
  }
}
