.header-link {
  --multi-ads-right-margin: 8px;

  display: block;
  text-decoration: none;
  color: #2b87db;

  &.common {
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
  }

  &.multi-ads {
    margin-right: var(--multi-ads-right-margin);
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }

  &.subtitle-multi-ads {
    margin-top: 4px;
    margin-right: var(--multi-ads-right-margin);
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
}
