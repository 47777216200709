.button-text {
  margin: 0 0 0 9px;
  display: flex;
  flex-direction: column;
}

.button-text-link {
  font-size: 14px;
  line-height: 20px;
  color: #2b87db;
}

.button-text-link:hover {
  color: #256ba5;
}

.button-text-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #121212;
}

.icon {
  width: 37px;
  height: 37px;
}

.analytics-button {
  margin-top: 20px;
  display: flex;
  cursor: pointer;
}
