.underground-container {
  display: flex;
  flex-flow: row nowrap;
}

.underground-container.without-dot .remoteness {
  margin-left: 0;
}

.icons {
  margin-right: 5px;
  box-sizing: border-box;
}

.underground-name {
  margin-right: 5px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  color: rgba(18, 18, 18, 0.92);
  transition: color 0.3s ease;
}

.remoteness {
  margin-left: 5px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #7a7a7a;
}

.underground-link {
  display: flex;
  white-space: nowrap;
  text-decoration: none;
  flex-flow: row nowrap;

  &:hover .underground-name {
    color: #e84260;
  }
}

.constructing {
  color: #7a7a7a;
}
