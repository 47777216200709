.button {
  font-weight: normal;
  color: #121212;
  border-color: #c9c9c9;

  &--checked {
    background: red;
  }
}
.button:hover {
  color: #121212;
  border: 1px solid #7a7a7a;
}
