.badge {
  z-index: 1;
  display: block;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-image: url('assets/ic-video.svg');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;

  &::before {
    position: absolute;
    top: 33px;
    right: 10px;
    display: none;
    width: 0;
    height: 0;
    content: '';
    border-width: 0 5px 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent;
  }

  &::after {
    position: absolute;
    top: 38px;
    right: -5px;
    padding: 5px 13px 7px 12px;
    display: none;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: 14px;
    white-space: nowrap;
    content: attr(data-tooltip-text);
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
  }

  &:hover {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);

    &::before,
    &::after {
      display: block;
    }
  }
}
