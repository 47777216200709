.container {
  display: flex;
  background-color: white;
}

.button {
  position: relative;
  font-weight: bold;

  &:hover {
    z-index: 2;
  }

  &--middle,
  &--last {
    padding-left: 12px;
    margin-left: -1px;
  }

  &--first {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:active {
      transform-origin: right center;
    }
  }

  &--middle {
    border-radius: 0;

    &:active {
      transform: scaleY(0.96);
    }
  }

  &--last {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:active {
      transform-origin: left center;
    }
  }
}
