.button {
  border: none;
  padding: 0 8px 2px;
  background-color: var(--error_100);
  cursor: pointer;
  outline: none;
  border-radius: 2px;
  transition: 0.1s background-color ease;

  &:hover {
    background-color: var(--fill_error_primary_hover_100_);
  }
}
