.horizontal-card-content-multi-ads {
  box-sizing: border-box;
  width: 100%;
  max-width: calc(100% - 554px);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.media_wrapper {
  display: flex;
  flex-direction: column;
}

.media {
  position: relative;
  min-width: 554px;
  height: 400px;
}

.gallery-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--gray6_100);
  border-radius: 4px;
}

.main {
  margin-left: 24px;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: space-between;
}

.horizontal_agent_brand {
  display: none;
}

@media (max-width: 1240px) {
  .horizontal_agent_brand {
    display: block;
  }
}

.agent-wrapper {
  width: 100%;
}

.price-partial {
  display: inline-block;
}
