.wrapper {
  position: relative;
}

.contacts {
  right: -3px;
  padding: 32px 24px 50px;
  box-sizing: border-box;
  min-width: 270px;
}

.contact {
  margin-bottom: 26px;
  display: flex;
  flex-direction: row;
}

.contact:last-child {
  margin-bottom: 0;
}

.contact-avatar {
  width: 42px;
  height: 42px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}

.contact-info {
  padding-left: 10px;
}

.contact-name {
  margin-bottom: 2px;
  display: block;
  font-weight: 700;
  line-height: 21px;
}

.toggle::after {
  margin-left: 10px;
  display: inline-block;
  width: 10px;
  height: 6px;
  vertical-align: middle;
  content: '';
  background-image: url('./assets/arrow.svg');
  background-repeat: no-repeat;
  transition: transform 0.15s linear 0s;
}

.toggle.active {
  background-color: #256ba5;
}

.toggle.active::after {
  transform: rotate(180deg);
}
