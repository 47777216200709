.container {
  width: 152px;
  height: 28px;

  &:hover {
    .buttonText {
      &--phone {
        display: none;
      }

      &--hideable {
        display: block;
      }
    }
  }
}

.button {
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.buttonText {
  &--phone {
    font-size: 16px;
    font-weight: normal;
  }

  &--hideable {
    display: none;
  }
}

.phone {
  font-size: 16px;
  line-height: 28px;
  color: #121212;
}
