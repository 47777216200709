.content {
  padding: 24px 40px;
  margin-top: -24px;
}

.header {
  margin-bottom: 16px;
}

.footer {
  margin-top: 24px;
}

.input-row {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.input-label {
  padding-right: 30px;
  width: 226px;
}

.input-max_bet {
  text-align: right;
  flex-grow: 1;
}

.buttons {
  display: flex;

  button + button {
    margin-left: 8px;
  }
}

.buttons-right {
  justify-content: flex-end;
}
