.official-representative {
  position: relative;
  margin-top: 24px;
  padding-left: 20px;
  line-height: 20px;
}

.official-representative::before {
  position: absolute;
  left: 0;
  display: block;
  width: 15px;
  height: 20px;
  content: '';
  background-image: url('./assets/ic-representative.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.link {
  text-decoration: none;
  color: #2b87db;
  transition: color 0.15s;
  cursor: pointer;
  line-height: 19px;
  font-weight: normal;

  &:hover {
    color: #256ba5;
  }
}
