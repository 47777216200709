@import '@cian/ui-kit/colors/variables.css';

.container {
  position: relative;
}

.addon-left {
  display: flex;
  align-items: center;
  padding: 0 8px;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  z-index: 1;
}

.addon-right {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
}

input.input {
  padding: 3px 156px 3px 32px;
  height: auto;
  font-size: 14px;
  line-height: 20px;
  color: var(--black_100);
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
  outline: none;
}

input.input:hover,
input.input:focus {
  border-color: var(--black_100);
}

input.input::placeholder {
  font-size: 14px;
  line-height: 20px;
  color: var(--gray40_100);
}

.suggest-two-lines div[data-group='addresses'] span[class*='relative'] {
  /* stylelint-disable-next-line */
  display: -webkit-box;
  overflow: hidden;
  line-height: 20px;
  white-space: pre-line;
  text-overflow: ellipsis;
  /* stylelint-disable-next-line */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding: 4px 0;
}
