@import '@cian/ui-kit/colors/variables.css';

.breadcrumbs {
  margin-bottom: 24px;
  grid-area: breadcrumbs;
}

.breadcrumbs-link {
  line-height: 20px;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  color: var(--gray60_100);
  border: none;
  transition: color 0.15s;
}

.breadcrumbs-link:hover {
  color: var(--black_60);
}

.breadcrumbs-delimiter {
  position: relative;
  margin: 0 6px;
  display: inline-block;
}

.breadcrumbs-delimiter::before {
  content: '/';
}

@media print {
  .breadcrumbs {
    display: none;
  }
}
