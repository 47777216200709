.container {
  padding: 26px 0;
  display: flex;
  background-color: white;
  border-top: 1px solid #e4e4e4;

  &:hover {
    background-color: #eef9ff;

    .comment-delete {
      display: block;
    }
  }

  &--top3 {
    background-color: rgb(255, 244, 212);

    &:hover {
      background-color: rgb(255, 236, 189);
    }
  }

  &--colorized {
    background-color: rgb(255, 244, 212);

    &:hover {
      background-color: rgb(255, 236, 189);
    }
  }
}

.container.simplified {
  position: relative;
  padding: 12px 0 12px 35px;
  background-color: #f4f4f4;
  border: 1px solid transparent;
  transition:
    background-color 0.3s ease,
    border 0.3s ease;

  &.simplified-editing {
    background-color: transparent;
    border: 1px solid #121212;
  }

  .content,
  .content-editing {
    padding-right: 0;
  }

  &::before {
    position: absolute;
    top: 17px;
    left: 16px;
    display: block;
    width: 11px;
    height: 11px;
    content: '';
    background: url('icons/note.svg') center no-repeat;
  }

  .title {
    display: none;
  }

  .save {
    position: absolute;
    top: 7px;
    right: 12px;
    margin: 0;
  }

  .container-top3,
  .container--colorized {
    background-color: transparent;
  }
}

.title {
  margin-right: 36px;
  padding-left: 24px;
  box-sizing: border-box;
  font-size: 13px;
  font-style: italic;
  color: #7a7a7a;
  flex: 0 0 20%;
}

.content {
  padding-right: 76px;
  width: 100%;

  &--editing {
    padding-right: 20px;
  }
}

.save {
  margin-right: 36px;
  align-self: center;
}

.comment-delete {
  display: none;
}

@media print {
  .container {
    display: none;
  }
}
