@import '@cian/ui-kit/colors/variables.css';

.link {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  margin: 8px 0;
}

.link-title {
  flex-shrink: 0;
  color: var(--primary_100);
  order: 0;
}

.link-count {
  flex-shrink: 0;
  color: var(--gray60_100);
  order: 3;
}

.link::after {
  /* выглядит монструозно, но решает задачу лучше чем background-image с повтором */
  /* количество подобрано для отображения ссылок в 3 колонки */
  content: '............................................................................'
    '......................................';
  color: var(--gray20_100);
  flex-grow: 1;
  order: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}
