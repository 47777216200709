.container {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  background: linear-gradient(90deg, #ffebaf 1.87%, #ffdcc8 50.93%, #ffe1ff 100%);
  border-radius: 4px;
  padding: 20px 52px 24px 20px;
  cursor: pointer;
}

.heading-wrapper {
  margin-bottom: 4px;
}

.button {
  margin-top: 16px;
}

.image-container {
  height: 100px;
  width: 100px;
  background: url('https://cdn.cian.site/frontend/commercial-assets/images/multi_ads_banner_icon.png');
  background-size: cover;
}
