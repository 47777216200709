.root {
  /* к сожалению, того требует дизайн, чтоы */
  input + span {
    padding: 9px 16px;
  }
}

.label {
  margin-bottom: 6px;
}
