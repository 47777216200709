.report-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;

  @media (max-width: 1240px) {
    padding: 0;
    gap: 0;

    .report-button-text {
      display: none;
    }
  }
}

.button-active {
  background-color: rgba(43, 135, 219, 0.15);
}

.identical-offers-button {
  padding-right: 12px;
  padding-left: 12px;
}

.identical-offers-count {
  margin-right: 4px;
  padding: 0 6px;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  color: white;
  background: #3a6ca1;
  border-radius: 8px;
}

@media (max-width: 1240px) {
  .identical-offers-button {
    padding: 0;
    width: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
  }

  .identical-offers-count {
    margin-right: 0;
  }

  .identical-offers-title {
    display: none;
  }
}

.popup-container {
  position: relative;
  z-index: 8;
  transform: translateX(10px);
}

div.popup-container.popup-container--big {
  right: 10px;
}

.more-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  color: #2b87db;

  &:hover {
    color: #256ba5;
  }
}

.more-item + .more-item {
  margin-top: 12px;
}

.more-link,
.more-item {
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  transition: color 0.3s ease;

  &:hover {
    color: #e84260;
  }
}

.like-container {
  position: relative;
}

.more-info-button {
  margin-left: 8px;
}

@media (max-width: 1200px) {
  .more-info-button {
    display: none;
  }
}

.send-message-button-wrapper {
  position: relative;
  margin-top: auto;
  margin-left: 8px;
  height: 28px;
}

.send-message-button {
  padding-left: 36px;
}

.send-message-button svg {
  position: absolute;
  top: 5px;
  left: 16px;
}

@media (max-width: 992px) {
  .send-message-button-wrapper {
    display: none;
  }
}
