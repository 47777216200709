@import '@cian/ui-kit/colors/variables.css';

.banner {
  display: flex;
  position: relative;
  justify-content: center;
  height: 122px;
  width: 100%;
  background: rgba(4, 104, 255, 0.1);
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 24px;
  max-width: 1336px;
}

.img_left_side--left {
  position: absolute;
  left: 40px;
  top: 58px;
  width: 34px;
  height: 34px;
}

.img_left_side--center {
  position: absolute;
  left: 157px;
  top: 21px;
  width: 27px;
  height: 27px;
}

.img_left_side--right {
  position: absolute;
  left: 251px;
  top: 55px;
  width: 32px;
  height: 32px;
}

.img_right_side--left {
  position: absolute;
  right: 250px;
  top: 62px;
  width: 30px;
  height: 30px;
}

.img_right_side--center {
  position: absolute;
  right: 140px;
  top: 15px;
  width: 34px;
  height: 34px;
}

.img_right_side--right {
  position: absolute;
  right: 28px;
  top: 46px;
  width: 40px;
  height: 40px;
}

.content {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-bottom: 22px;
}

.name {
  color: var(--primary_100);
}
