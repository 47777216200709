/* stylelint-disable selector-class-pattern */

.promoInfo {
  position: absolute;
  z-index: 1;
  top: 10px;
  box-sizing: border-box;
  height: 24px;
  font-size: 13px;
  cursor: pointer;
  color: white;
  background-color: #ff5050;

  &::before {
    position: absolute;
    left: 100%;
    bottom: 0;
    width: 0;
    height: 0;
    content: '';
    border-top: 12px solid #ff5050;
    border-left: 10px solid #ff5050;
    border-right: 10px solid rgba(255, 0, 0, 0);
    border-bottom: 12px solid #ff5050;
  }
}

.promoInfo-text {
  padding: 0 10px;
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.promoSaleInfo {
  color: black;
  background-color: #feeaea;

  &::before {
    border-top: 12px solid #feeaea;
    border-left: 10px solid #feeaea;
    border-right: 10px solid rgba(255, 0, 0, 0);
    border-bottom: 12px solid #feeaea;
  }
}

@media print {
  .promoInfo {
    display: none;
  }
}
