.content {
  display: grid;
  grid-row-gap: 4px;
  max-width: 300px;
}

.link {
  display: flex;
  text-decoration: none;
}
