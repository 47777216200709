.like-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
}

@media (max-width: 1240px) {
  .like-button {
    padding: 0;
    gap: 0;
  }

  .button-text {
    display: none;
  }
}
