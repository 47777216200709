.row {
  width: 230px;
  min-width: 230px;
}

.title {
  margin-bottom: 20px;
  padding: 0 30px;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
}

.subtitle {
  padding: 25px 30px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.calculator {
  display: table;
  width: 100%;
}

.m2,
.sublabel {
  margin-right: 8px;
  margin-left: 12px;
  display: inline-block;
}

.recomendation {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  vertical-align: middle;
  color: #7a7a7a;
}

.content-wrapper {
  padding-bottom: 60px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  box-sizing: content-box;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid #d8d8d8;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.result {
  margin-top: 40px;
  padding: 0 30px 40px;

  &--noResult {
    margin-top: 0;
    padding: 20px 0;
  }
}

.resultContainer {
  padding: 20px 0 24px;
  text-align: center;
  background: #f4f4f4;
}

.resultSquare {
  margin-bottom: 4px;
  font-size: 28px;
  line-height: 36px;
}

.resultText {
  font-size: 14px;
  line-height: 22px;
}

.row {
  display: table-row;
  width: 100%;
  line-height: 26px;

  &:hover {
    background-color: #edf5fc;
  }
}

.cellHead {
  padding: 14px 30px;
  display: table-cell;
  width: 250px;
  min-width: 250px;
  line-height: 20px;
  vertical-align: top;
  word-break: normal;
}

.cellContent {
  padding: 12px 30px 4px 10px;
  display: table-cell;
}

.required-sign {
  position: relative;

  &::before {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: -8px;
    border-radius: 50%;
    background-color: #ff5050;
    width: 4px;
    height: 4px;
    content: '';
  }
}

.tooltip-content {
  color: var(--white_100);
}
