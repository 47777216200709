@import '@cian/ui-kit/typography/variables.module.css';

.form {
  margin: 16px 0;
}

.field--main {
  display: flex;
  flex-direction: row;
  max-width: 400px;
}

.field--main > button {
  margin-left: 8px;
}

.license a,
.license a:hover {
  text-decoration: none;
  color: var(--primary_100);
}

.message--succeed {
  margin-top: 16px;
  padding-left: 20px;
  background: url('./assets/succeed.svg') left bottom 5px no-repeat;
}
