.container {
  position: relative;
  display: block;

  @media (max-width: 1200px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 24px;

    @media (max-width: 1200px) {
      margin-left: 10px;
    }
  }

  &:first-child {
    margin-left: 0;

    @media (max-width: 1200px) {
      margin-left: 0;
    }
  }

  .button {
    padding: 3px 16px 5px;
    display: flex;
    flex-flow: row nowrap;
    font-size: 13px;
    line-height: 18px;
  }

  .button-text {
    margin: auto 0 auto 4px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
  }

  .icon-container {
    padding: 3px 0 2px;
    height: 15px;
  }

  /* camelCase согласно настройкам stylelint проекта */
  .button--hideText {
    padding: 0;
    width: 28px;
    height: 28px;

    .button-text {
      display: none;
    }

    .icon-container {
      margin: auto;
      padding: 0;
    }
  }
}

.heartbeat {
  animation: heartbeat 1s linear 10;
}

@media (max-width: 992px) {
  .container {
    margin: auto 20px;
    display: block;
  }

  .container .button-text {
    display: none;
  }

  .container .button {
    padding: 0;
    width: 28px;
    height: 28px;
  }

  .container .icon-container {
    margin: auto;
    padding: 0;
  }
}

@keyframes heartbeat {
  0%,
  100% {
    transform: scale(0.95);
  }

  20% {
    transform: scale(1.1);
  }
}

.hover-hint:hover {
  &::after {
    position: absolute;
    z-index: 1;
    display: inline-block;
    top: 36px;
    right: 0;
    min-width: 345px;
    padding: 4px 16px 6px;
    font-size: 12px;
    color: #7a7a7a;
    content: 'Сохраните поиск, чтобы не настраивать каждый раз фильтры';
    border-radius: 2px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
}
