.similar {
  display: flex;
  font-size: 13px;
  line-height: 44px;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  background-color: white;
  border-top: 1px solid #e4e4e4;
  align-items: center;
  justify-content: center;
}

.similar:hover {
  color: #e84260;
  background-color: #eef9ff;
}

.similar--top3,
.similar--colorized {
  background-color: rgb(255, 244, 212);
}

.similar--top3:hover,
.similar--colorized:hover {
  background-color: rgb(255, 236, 189);
}

@media print {
  .similar {
    display: none;
  }
}
