@import '@cian/ui-kit/colors/variables.css';

.container {
  background-color: var(--gray10_100);
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  padding-bottom: 20px;
  padding-top: 16px;
  width: 100%;
}

.content-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.subtitle {
  margin-bottom: 16px;
  margin-top: 4px;
}

.icons-group {
  display: flex;
}

.icon-cactus {
  margin-right: 48px;
}

.icon-coffee-cup {
  align-self: center;
  margin-right: 15px;
}

.icon-horns {
  align-self: center;
  margin-left: 16px;
}

.icon-lamp {
  margin-left: 1px;
}

@media (max-width: 976px) {
  .icon-lamp,
  .icon-cactus {
    display: none;
  }
}
