.container {
  position: relative;
  background-color: #e4e4e4;
}

/* stylelint-disable-next-line selector-class-pattern */
.noImage {
  position: absolute;
  inset: 0;
}
