.hint_wrapper {
  position: relative;
  margin-left: 4px;
  display: inline-block;
}

.popup_container {
  text-align: left;
  white-space: nowrap;
}
