.wrapper {
  padding: 13px 42px 21px 18px;
  display: inline-block;
  min-width: 266px;
  font-size: 14px;
  line-height: 21px;
  background-color: #eaf3fc;
  border-radius: 3px;
}

.cian-logo,
.logo {
  position: relative;
  margin-right: 10px;
  display: inline-block;
  overflow: hidden;
  width: 44px;
  height: 44px;
  vertical-align: top;
  background-color: white;
  border-radius: 2px;
}

.logo-image {
  position: absolute;
  top: 50%;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  transform: translateY(-50%);

  &--placeholder {
    width: 100%;
    height: 100%;
    background: url('./assets/builder_face.svg');
  }
}

.wrapper--cian {
  .name {
    margin-bottom: 2px;
    border: none;

    &:hover {
      border: none;
    }
  }
}

.subtitle {
  margin-bottom: 12px;
  color: #737a8e;
}

.cian-logo {
  background-image: url('./assets/cian_logo.svg');
  background-size: 22px 31px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
}

.info {
  display: inline-block;
}

.name {
  margin-bottom: 5px;
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  color: #121212;
  border-bottom: 1px solid transparent;
}

.name:hover {
  border-bottom-color: hsla(0, 0%, 7%, 0.3);
}

.description {
  white-space: nowrap;
  color: #7a7a7a;
}

.phone {
  margin-top: 10px;
}
