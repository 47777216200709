:root {
  --photoWidth: 20%;
  --minOfferHeight: 246px;
}

.offer-container {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  max-height: 3000px;
  border: 1px solid #e8e8e8;
  transition: opacity 0.5s;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: #deeaf1;
  }

  &:not(:hover) {
    .outside_hidden {
      visibility: hidden;
    }
  }

  &.represent {
    border-color: rgba(46, 158, 0, 0.3);

    &:hover {
      border-color: rgba(46, 158, 0, 0.3);
    }
  }

  &:hover .dinamic-buttons,
  &.touch-device .dinamic-buttons {
    opacity: 1;
  }
}

.offer--hide {
  opacity: 0;
}

.offer--hidden {
  margin: 0;
  overflow: hidden;
  max-height: 0;
  border: none;
}

.hide-offer-animation {
  overflow: hidden;
  max-height: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  transition:
    max-height 0.7s,
    border-bottom-width 0.7s,
    border-top-width 0.7s;
}

.photo-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.photo {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: none;
}

.footer-menu {
  margin-top: 4px;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: space-between;
}

.footer-flags {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: flex-end;
}

@media (max-width: 1150px) {
  .footer-flags {
    align-self: flex-end;
  }
}

.hidden_base_label {
  padding-top: 6px;
  padding-left: 20px;
}

.promo-container {
  max-width: calc(100% - 30px);
}

.new-label {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 8px;
  padding: 3px 8px 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: white;
  background-color: #ff9d00;
  border-radius: 2px;
}

.offer--top3 {
  padding: 16px 20px 20px;
}

@media print {
  .offer-container {
    page-break-inside: avoid;
  }

  .content {
    padding-left: 16px;
    width: auto;
  }
}

.dinamic-buttons {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.online-show {
  margin-bottom: 8px;
}

.author_online-show {
  padding-top: 8px;
}
