.tooltip {
  display: flex;
  flex: 0 0 auto;
}

.container {
  position: absolute;
  z-index: 999;
  top: -72px;
  margin-left: -125px;
  padding-bottom: 40px;
  display: flex;
  visibility: hidden;
  width: 270px;
  flex: 0 0 auto;
  transition: transform 0.35s ease-out;
}

.content {
  padding: 7px 12px 9px;
  box-sizing: border-box;
  height: auto;
  font-family: Lato, sans-serif;
  font-size: 14px;
  background-color: white;
  border: 1px solid #f4f4f4;
  border-radius: 2px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.tooltip.open .container {
  visibility: visible;
}

.icon svg {
  margin-left: 5px;
  vertical-align: bottom;
}

.icon circle {
  stroke: transparent;
  /* stylelint-disable-next-line value-keyword-case */
  fill: currentColor;
}
