.wrapper {
  margin: 0 auto 24px;
  padding: 0 20px;
  width: 100%;
  max-width: 1376px;
}

.banner {
  height: 135px;
  padding: 16px 64px;
  border-radius: 4px;
  background-image: url('./assets/background.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.header {
  margin-bottom: 4px;
}

.sub_header {
  margin-bottom: 12px;
}

@media (max-width: 1279px) {
  .banner {
    padding-left: 20px;
  }
}

/* stylelint-disable-next-line */
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .banner {
    background-image: url('./assets/background@2x.png');
  }
}
