.other_offers {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  transition: color 0.3s ease;

  &:hover {
    color: #256ba5;
  }
}
