.wrapper {
  margin: -14px auto 2px;
  padding: 0 13px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

/**
 * Минимальная ширина для desktop = 1272px
 * Максимальная ширина для desktop = 1376px
 * Отступы между карточками = 24px
 * Отступы по бокам слайдера = 20px
 * Количество карточек = 4
 * min-width = (1135 - 14 * 3 - 20 * 2) / 4 = 263
 * max-width = (1376 - 14 * 3 - 20 * 2) / 4 = 323
 * flex-basis = 100% / 4 = 25%
 */
.desktop-card {
  min-width: 277px;
  max-width: 337px;
  flex-basis: 25%;
  padding: 14px 7px;
}
