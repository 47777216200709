.label {
  font-weight: bold;
  line-height: 1.43;
  color: #ff9d00;
}

.label span:not(:first-child) {
  text-transform: lowercase;
}

.label span:not(:last-child)::after {
  content: ', ';
}
