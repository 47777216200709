:root {
  --x-padding: 32px;
}

.locationSwitcher {
  margin: auto;
  padding: 130px var(--x-padding) 0;
  max-width: 1192px;
  max-height: calc(100vh - 120px);
  background-color: white;
}

.header {
  padding: 24px var(--x-padding) 20px;
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.footer {
  display: flex;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid #d8d8d8;
  align-items: center;
  justify-content: center;
}

.preloaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}

.preloader {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  margin: auto;
}
