.container {
  display: flex;
  align-items: flex-start;
  line-height: 18px;
}

.text-wrapper {
  margin-left: 4px;
  display: flex;
}

.success-icon-wrapper {
  margin-top: 2px;
  display: flex;
}
