@import '@cian/ui-kit/colors/variables.css';

.banner {
  flex-basis: 25%;
  flex-shrink: 1;
  position: relative;
}

.left-border {
  margin-left: 34px;
  padding-left: 35px;
}

.right-border {
  margin-right: 34px;
  padding-right: 35px;
}

.left-border::before,
.right-border::before {
  content: '';
  position: absolute;
  width: 1px;
  top: 4px;
  bottom: 4px;
  background: var(--gray10_100);
}

.left-border::before {
  left: 0;
}

.right-border::before {
  right: 0;
}
