.container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 10px 8px 0;
}

.has-video {
  /* если на фото есть иконка "Объявление с видео", добавляем отступ, чтобы лейблы не заезжали на нее */
  padding-right: 48px;
}

.label {
  margin-bottom: 8px;
  padding: 0 8px 2px;
  display: inline-block;
  height: 20px;
  font-size: 12px;
  line-height: 18px;
  color: white;
  border-radius: 2px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.orange-label {
  background: #ff7e00;
}

.blue-label {
  background: #0468ff;
}

.grey-label {
  background: #737a8e;
}

.green-label {
  background: #3ac500;
}
