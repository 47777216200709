.container {
  margin-left: 4px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.43;
  color: #7a7a7a;

  a {
    text-decoration: none;
    color: #7a7a7a;
  }

  a:visited {
    color: #7a7a7a;
  }

  a:hover {
    color: #e84260;
  }
}

.container span {
  opacity: 0;
  transition: opacity 0.3s ease 0.2s;
}

.container-active span {
  opacity: 1;
}
