.header {
  padding: 20px 32px;
  padding-right: 66px;
  display: flex;
  background: white;
  border-bottom: 1px solid #d8d8d8;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  & > :not(:first-child) {
    margin-left: 8px;
  }
}
